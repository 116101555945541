html {
  color: #fff;
  background-color: #000;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.5em;
  line-height: 1.4;
}

body {
  grid-template-areas: "header"
                       "intro"
                       "job"
                       "books"
                       "socials"
                       "aside";
  gap: .5em;
  padding: 8% 8% 0%;
  display: grid;
}

::selection {
  text-shadow: none;
  background: #a0a0a0;
}

a, a:visited {
  color: #fff;
}

.magic {
  color: #0000;
  background-image: linear-gradient(100deg, #21dcf3 0%, #e23aff 100%);
  background-clip: text;
  font-weight: bold;
}

.magic-border {
  position: relative;
}

.magic-border:before, .magic-border:after {
  content: "";
  z-index: -1;
  background: linear-gradient(45deg, #21dcf3, #e23aff, #21dcf3, #e23aff) 0 0 / 400%;
  border-radius: 10px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  animation: 20s linear infinite border-animation;
  position: absolute;
  top: -1px;
  left: -1px;
}

.magic-border:after {
  filter: blur(64px);
}

.magic-border:hover:before, .magic-border:hover:after {
  filter: none;
  background: none;
}

@keyframes border-animation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 200% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  max-width: fit-content;
  padding-top: .5em;
  display: flex;
}

main section {
  padding: .5em 0;
}

header {
  grid-area: header;
  font-family: Montserrat, sans-serif;
  font-size: 2.5em;
  line-height: 1.1;
}

.intro {
  grid-area: intro;
  max-width: 800px;
}

.job {
  grid-area: job;
}

.books {
  grid-area: books;
  padding-top: .5em;
}

.book {
  flex-direction: column;
  align-items: center;
  gap: .5em;
  max-width: 200px;
  display: flex;
}

.book-container {
  max-height: 250px;
  transform: scale(1);
}

.book-container:hover {
  z-index: 100;
  max-height: auto;
  transform: scale(1.1);
}

@media (hover: none) {
  .book-container:hover {
    transform: scale(1);
  }
}

.picture-book {
  border-radius: 10px;
  width: 100%;
}

.book-container, .picture-book {
  transition: all .3s ease-out;
}

.socials {
  grid-area: socials;
  padding-top: 1em;
}

.socials .list {
  padding-top: 0;
}

.social svg {
  fill: #fff;
  opacity: .8;
  transition: all .3s ease-in-out;
}

.social svg:hover {
  z-index: 100;
  opacity: 1;
  transform: scale(1.1);
}

@media (hover: none) {
  .social svg:hover {
    opacity: .8;
    transform: scale(1);
  }
}

aside {
  grid-area: aside;
}

aside picture {
  justify-content: center;
  display: flex;
}

.picture-me {
  width: 50%;
}

@media only screen and (width >= 600px) {
  .picture-me {
    width: 30%;
  }
}

@media only screen and (width >= 768px) {
  body {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "header aside"
                         "intro aside"
                         "job aside"
                         "books aside"
                         "socials aside";
    padding: 5% 25% 5% 5%;
  }

  aside picture {
    justify-content: flex-start;
    display: flex;
  }

  .picture-me {
    width: auto;
    height: 80%;
    position: fixed;
    bottom: 0;
  }
}

@media only screen and (width >= 768px) and (orientation: portrait) {
  .picture-me {
    max-height: 50%;
    right: .5em;
  }
}

@media only screen and (width >= 992px) {
  html {
    font-size: 2em;
  }

  body {
    padding: 5% 25% 5% 10%;
  }

  .picture-me {
    height: 90%;
  }
}

@media only screen and (width >= 1200px) {
  body {
    padding: 5% 35% 5% 15%;
  }
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: attr(title) " (" attr(href) ")";
  }

  .list {
    flex-direction: column;
  }

  section {
    page-break-inside: avoid;
  }

  .book {
    width: 100%;
    max-width: 100%;
  }

  .book img {
    max-width: 200px;
  }

  .socials .list {
    padding-top: .5em;
  }

  .social svg, .picture-me {
    display: none;
  }
}
/*# sourceMappingURL=index.77c28a76.css.map */
